import { Box, Button, Divider, Grid, Link, Menu, MenuItem, Stack } from '@mui/material'
import { useState, type ReactNode } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import type { AuthenticatedUser } from '../../lib/auth'
import type { UrlInputs } from '../../lib/url'
import { getRequestUrl } from '../../lib/url'

type Props = {
  children: ReactNode
  user: AuthenticatedUser | null
  urlInputs: UrlInputs
  headerContent?: ReactNode
}

const pages = [
  { urlPath: '/', text: 'Callbox' },
  { urlPath: '/ringer', text: 'Ringer' },
  { urlPath: '/cha-leaderboard', text: 'Cha Leaderbaord' },
]

export function FrameworkPage(props: Props) {
  const url = getRequestUrl(props.urlInputs)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack spacing={1} direction={'row'}>
        {props.user ? (
          <>
            <Button onClick={e => setAnchorEl(e.currentTarget)}>
              <MenuIcon />
            </Button>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
              {pages.map(page => {
                return (
                  <MenuItem key={page.urlPath}>
                    <Button disabled={page.urlPath === url.pathname} LinkComponent={Link} href={page.urlPath}>
                      {page.text}
                    </Button>
                  </MenuItem>
                )
              })}

              <Divider />
              <MenuItem>
                <Button LinkComponent={Link} href={'/api/google/logout'}>
                  Logout
                </Button>
              </MenuItem>
            </Menu>
          </>
        ) : null}

        {props.headerContent}
      </Stack>
      <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'}>
        <Stack direction={'column'} spacing={1}>
          {props.children}
        </Stack>
      </Grid>
    </Box>
  )
}
