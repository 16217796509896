import type { IncomingMessage } from 'http'

export type UrlInputs = Pick<IncomingMessage, 'url' | 'headers'>

export function urlInputsFromReq(req: IncomingMessage): UrlInputs {
  return {
    headers: req.headers,
    url: req.url,
  }
}

export function getRequestUrl(input: UrlInputs): URL {
  const proto = input.headers['x-forwarded-proto'] ?? 'http'
  const host = input.headers.host
  const remaining = input.url

  if (typeof proto !== 'string' || !host || !remaining) {
    throw new Error('Missing URL pieces')
  }

  return new URL(`${proto}://${host}${remaining}`)
}
